import {
	DeviceRPi,
	type Device,
	type ProjectorPowerState,
	ProjectorPowerManager,
	ThirdPartyProjectorManager,
	DataHandlerDevice,
} from "luxedo-data"
import { Controller } from "svelte-comps/stores"
import { SelectedDeviceStore } from "../../../../../stores/SelectedDeviceStore"
import { DataSaveError } from "../../../../../types/ErrorVariants"
import { LuxedoRPC } from "luxedo-rpc"

export type AdvancedSettingProperty_Name =
	| "powerStatus"
	| "resolution"
	| "isTimeoutActive"
	| "timeoutDuration"
	| "cameraExposure"
	| "invertCamera"
	| "audioOutput"

export type AdvancedSettingPropety_Value<T extends AdvancedSettingProperty_Name> =
	T extends "timeoutDuration"
		? number
		: T extends "isTimeoutActive"
		? boolean
		: T extends "invertCamera"
		? boolean
		: T extends "powerStatus"
		? ProjectorPowerState
		: T extends "resolution"
		? string
		: T extends "cameraExposure"
		? number // Change to number if that's the expected type
		: T extends "audioOutput"
		? "HDMI" | "HEADPHONES" // Match the exact type
		: never

type AdvancedSettingsContext_Offline = {
	isOnline: false
}

type AdvancedSettingsContext_Online = {
	isOnline: true
	isModified: boolean

	// General projector settings
	powerStatus?: ProjectorPowerState
	resolution?: string

	// Projector timeout settings
	isTimeoutActive?: boolean
	timeoutDuration?: number

	// Camera settings
	cameraExposure?: number
	invertCamera?: boolean

	// Audio settings
	audioOutput?: "HDMI" | "HEADPHONES"
}

type AdvancedSettingsContext = AdvancedSettingsContext_Offline | AdvancedSettingsContext_Online

class ProjectorAdvancedSettingsController extends Controller<AdvancedSettingsContext> {
	device: Device
	eidosListener: string
	hasInitialized: boolean

	private modifiedProperties: {
		[K in AdvancedSettingProperty_Name]?: AdvancedSettingPropety_Value<K>
	} = {}

	constructor() {
		super({
			isOnline: false,
		})

		SelectedDeviceStore.subscribe(this.onSelectedDeviceChange)
	}

	// #region    ================== Initialization        ==================

	/**
	 * Called when the device store (which manages the user's currently selected device in the projector page) is updated.
	 * @param device
	 */
	private onSelectedDeviceChange = async (device: Device) => {
		if (this.eidosListener) {
			this.device?.removeUpdateListener(this.eidosListener)
			this.eidosListener = undefined
		}
		if (!device) return this.reset()
		if (!device.isOnline) this.reset()

		this.hasInitialized = false
		this.device = device

		console.log({ SELECTEDDEVICEUPDATE: device })

		if (!device.isOnline) {
			await device.awaitPower(true)
		}

		this.initializeSettings(device)
	}

	/**
	 * Called when the selected device changes - initializes the store with device settings
	 * @param device
	 */
	private initializeSettings(device: Device) {
		if (device && device.isOnline && !this.hasInitialized) {
			this.modifiedProperties = {}
			let deviceSettings = {}

			const availProps = this.getAvailableProperties(device)
			for (const prop of availProps) {
				deviceSettings[prop] = this.getInitialPropertyValue(device, prop)
			}

			this.store.set({
				isOnline: true,
				isModified: false,
				...deviceSettings,
			})

			this.hasInitialized = true

			this.eidosListener = device.addUpdateListener(() => {
				if (!device.isOnline) return this.onSelectedDeviceChange(device)
			})
		} else {
			this.hasInitialized = false
			this.store.set({
				isOnline: false,
			})
		}
	}

	// #endregion ================== Initialization        ==================
	// #region    ================== Get Device Properties ==================

	/**
	 * Gets a list of advanced settings which are available for the specified device
	 * @param device the device to get the available properties
	 * @returns a list of available properties
	 */
	public getAvailableProperties(device: Device) {
		let properties: Array<AdvancedSettingProperty_Name> = []

		if (device && device.hasConnectedProjector) {
			properties.push("powerStatus")
			properties.push("resolution")
			properties.push("isTimeoutActive")
			properties.push("timeoutDuration")
			properties.push("cameraExposure")
			properties.push("audioOutput")
		}

		if ("orientation" in device) {
			properties.push("invertCamera")
		}

		return properties
	}

	/**
	 * Gets the initial value of the specified proeprty from the passed device
	 */
	public getInitialPropertyValue<T extends AdvancedSettingProperty_Name>(
		device: Device,
		property: T
	): AdvancedSettingPropety_Value<T> {
		console.warn("GETTING INITIAL PROPERTY", property)

		let dev: DeviceRPi = device as DeviceRPi
		switch (property) {
			case "timeoutDuration":
				return (dev.getEidos()?.config?.fw_config?.projector_keep_alive_duration / 60 ??
					0) as AdvancedSettingPropety_Value<T>
			case "isTimeoutActive":
				return (dev.getEidos()?.config?.fw_config?.projector_keep_alive_duration ??
					0 > 0) as AdvancedSettingPropety_Value<T>
			case "invertCamera":
				return (dev.orientation ?? false) as AdvancedSettingPropety_Value<T>
			case "powerStatus":
				return ProjectorPowerManager.get(dev.id).state as AdvancedSettingPropety_Value<T>
			case "resolution":
				if (!dev.resX && "eidos" in dev && "display_config" in dev.eidos) {
					return ThirdPartyProjectorManager.resolutionManager.getByResolution(
						dev.eidos.display_config[0],
						dev.eidos.display_config[1]
					) as AdvancedSettingPropety_Value<T>
				} else {
					return ThirdPartyProjectorManager.resolutionManager.getByResolution(
						dev.resX,
						dev.resY
					) as AdvancedSettingPropety_Value<T>
				}
			case "cameraExposure":
				return (dev?._rawData?.recommended_exposure?.toString() ??
					"-1") as AdvancedSettingPropety_Value<T>
			case "audioOutput":
				console.log(`getting audio output as ${dev?.eidos?.config?.fw_config?.audio_device}`)
				return dev?.eidos?.config?.fw_config?.audio_device as AdvancedSettingPropety_Value<T>
		}
	}

	// #endregion ================== Get Device Properties ==================
	// #region    ================== Modifying Properties  ==================

	/**
	 * Modifies the local reference to the advanced setting - this will not be applied until 'saveModifiedProperties' is called.
	 * @param name the name of the property
	 * @param value the value of the property
	 */
	public modifyProperty<T extends AdvancedSettingProperty_Name>(
		name: T,
		value: AdvancedSettingPropety_Value<T>
	) {
		const current = this.get()
		if (current.isOnline && !current.isModified) this.update({ isModified: true })

		this.modifiedProperties[name] = value as any
	}

	/**
	 * Saves the modified properties (set in modifyProperty) to the device then resets to initial state after pulling the device's new settings
	 */
	public async saveModifiedProperties() {
		const device = this.device as DeviceRPi
		const configUpdate: typeof device.eidos.config.fw_config = {}
		const promises: Array<Promise<any>> = []

		for (const [name, value] of Object.entries(this.modifiedProperties)) {
			switch (name) {
				case "resolution":
					promises.push(
						ProjectorAdvancedSettingsController.saveResolution(this.device, value as string)
					)
					break
				case "timeoutDuration":
					configUpdate["projector_keep_alive_duration"] = Number(value) * 60
					break
				case "cameraExposure":
					promises.push(
						ProjectorAdvancedSettingsController.saveCameraExposure(this.device, value as number)
					)
					break
				case "invertCamera":
					promises.push(
						ProjectorAdvancedSettingsController.saveCameraInverted(this.device, value as boolean)
					)
					break
				case "audioOutput":
					configUpdate["audio_device"] = value as "HDMI" | "HEADPHONES"
					break
			}
		}

		if (Object.keys(configUpdate).length) {
			promises.push(LuxedoRPC.api.plato.plato_call("config_update", [configUpdate], device?.id!))
		}

		await Promise.all(promises)

		// await new Promise((res) => {
		// 	setTimeout(res, 10000) // wait for 10 sec to ensure properties apply properly before
		// })

		await DataHandlerDevice.pull([this.device.id])
		this.device = DataHandlerDevice.get(this.device.id)

		// if updating resolution, wait for the device to go offline before attempting to verify modified properties were saved
		if ("resolution" in this.modifiedProperties) await this.device.awaitPower(false)
		if ("resolution" in this.modifiedProperties) await this.device.awaitPower(true)

		// Make sure each modified value actually updates
		await this.device.listenEidosCondition(() => {
			let allFinished = true
			for (const [key, value] of Object.entries(this.modifiedProperties)) {
				if (
					!(this.getInitialPropertyValue(this.device, key as AdvancedSettingProperty_Name) == value)
				)
					allFinished = false
			}
			return allFinished
		}, 180)

		this.hasInitialized = false
		this.initializeSettings(this.device)
	}

	// #endregion ================== Modifying Properties  ==================
	// #region    ================== Property Save Methods ==================

	private static async saveResolution(device: Device, newRes: string) {
		const newResolution = ThirdPartyProjectorManager.resolutionManager.resolutions[newRes]

		if (!device?.isReady)
			throw new DataSaveError("Device is offline or busy - please wait and try again")
		if (!(device instanceof DeviceRPi) || !device?.hasConnectedProjector)
			throw new DataSaveError("Cannot change resolution of this device?.")
		if (device?.isResolutionChanging)
			throw new DataSaveError("Cannot change resolution while awaiting response.")

		if (device?.resX === newResolution.width && device?.resY === newResolution.height) return true
		device.isResolutionChanging = true

		device.resX = newResolution.width
		device.resY = newResolution.height

		await LuxedoRPC.api.plato.plato_call(
			"display_set_resolution",
			[newResolution.width, newResolution.height, 60, "_all_other_projectors"],
			device?.id!
		)

		device.isResolutionChanging = false
	}

	private static async saveCameraInverted(device: Device, isInverted: boolean) {
		await LuxedoRPC.api.deviceControl.device_set_camera_flipped(device?.id, isInverted ? 1 : 0)
	}

	private static async saveCameraExposure(device: Device, cameraExposure: number) {
		await LuxedoRPC.api.plato.plato_call("set_camera_exposure", [cameraExposure], device?.id!)
	}

	// #endregion ================== Property Save Methods ==================
}

export const AdvancedSettingsController = new ProjectorAdvancedSettingsController()
